<template>
  <el-card class="box-card">
    <v-popup v-show="create && actionType === 'services'" :title="service.id != null ? 'Изменить' : 'Добавить'"
             @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="service" label-position="left" label-width="auto">
        <el-form-item label="Название услуги" prop="title">
          <el-input v-model="service.title" placeholder="Введите название услуги" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название услуги на казахском" prop="title">
          <el-input v-model="service.title_kz" placeholder="Введите название услуги на казахском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название услуги на английском" prop="title">
          <el-input v-model="service.title_en" placeholder="Введите название услуги на английском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Описание услуги" prop="description">
          <el-input v-model="service.description" placeholder="Введите описание услуги"></el-input>
        </el-form-item>
        <el-form-item label="Описание услуги на казахском" prop="description">
          <el-input v-model="service.description_kz" placeholder="Введите описание услуги на казахском"></el-input>
        </el-form-item>
        <el-form-item label="Описание услуги на английском" prop="description">
          <el-input v-model="service.description_en" placeholder="Введите описание услуги"></el-input>
        </el-form-item>
        <el-form-item label="Стоимость (тг.)" prop="price">
          <el-input v-model="service.price" placeholder="Введите стоимость" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Доктор" prop="doctor_id">
          <el-select v-model="service.doctor_id" placeholder="Выберите врача" type="select">
            <el-option
                v-for="item in doctors"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Тип услуги" prop="request_type">
          <el-select v-model="service.request_type" placeholder="Выберите тип услуги" type="select">
            <el-option
                label="Не выбрано"
                :value="null"
            />
            <el-option
                label="Онлайн"
                :value="1"
            />
            <el-option
                label="Оффлайн"
                :value="2"
            />
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create && actionType === 'clinic-services'"
             :title="clinic_service.id != null ? 'Изменить' : 'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="clinic_service" label-position="left" label-width="auto">
        <el-form-item label="Название услуги" prop="title">
          <el-input v-model="clinic_service.title" placeholder="Введите название услуги" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название услуги на казахском" prop="title">
          <el-input v-model="clinic_service.title_kz" placeholder="Введите название услуги на казахском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название услуги на английском" prop="title">
          <el-input v-model="clinic_service.title_en" placeholder="Введите название услуги на английском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Описание услуги" prop="description">
          <el-input v-model="clinic_service.description" placeholder="Введите описание услуги"></el-input>
        </el-form-item>
        <el-form-item label="Описание услуги на казахском" prop="description">
          <el-input v-model="clinic_service.description_kz"
                    placeholder="Введите описание услуги на казахском"></el-input>
        </el-form-item>
        <el-form-item label="Описание услуги на английском" prop="description">
          <el-input v-model="clinic_service.description_en"
                    placeholder="Введите описание услуги на английском"></el-input>
        </el-form-item>
        <el-form-item label="Стоимость (тг.)" prop="price">
          <el-input v-model="clinic_service.price" placeholder="Введите стоимость" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Клиника" prop="doctor_id">
          <el-select v-model="clinic_service.clinic_id" placeholder="Выберите клинику" type="select">
            <el-option
                v-for="item in clinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create && actionType === 'checkups'" :title="checkup.id != null ? 'Изменить' : 'Добавить'"
             @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="checkup" label-position="left" label-width="auto" :rules="rules">
        <el-form-item label="Название чекапа" prop="title">
          <el-input v-model="checkup.title" placeholder="Введите название чекапа" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название чекапа на казахском" prop="title">
          <el-input v-model="checkup.title_kz" placeholder="Введите название чекапа на казахском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название чекапа на английском" prop="title">
          <el-input v-model="checkup.title_en" placeholder="Введите название чекапа на английском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Изображение" prop="image">
          <input id="files" ref="files" multiple type="file" v-on:change="handleFilesUpload()" required/>
        </el-form-item>
        <el-form-item label="Описание" prop="description">
          <el-input v-model="checkup.description" placeholder="Введите описание" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Описание на казахском" prop="description">
          <el-input v-model="checkup.description_kz" placeholder="Введите описание на казахском" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Описание на английском" prop="description">
          <el-input v-model="checkup.description_en" placeholder="Введите описание на английском"
                    type="text"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create && actionType === 'checkup_clinics'"
             :title="checkup_clinic.id != null ? 'Изменить' : 'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="checkup_clinic" label-position="left" label-width="auto">
        <el-form-item label="Чекап" prop="checkup_id">
          <el-select v-model="checkup_clinic.checkup_id" placeholder="Выберите чекап" type="select">
            <el-option
                v-for="item in checkups"
                :key="item.id"
                :label="item.title"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Клиники" prop="clinic_info_id">
          <el-select v-model="checkup_clinic.clinic_info_id" placeholder="Выберите клинику" type="select">
            <el-option
                v-for="item in clinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Стоимость" prop="price">
          <el-input v-model="checkup_clinic.price" placeholder="Введите стоимость" type="number"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create && actionType === 'checkup_services'"
             :title="checkup_service.id != null ? 'Изменить' : 'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="checkup_service" label-position="left" label-width="auto">
        <el-form-item label="Название услуги" prop="title">
          <el-input v-model="checkup_service.title" placeholder="Введите название услуги" type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название услуги на казахском" prop="title">
          <el-input v-model="checkup_service.title_kz" placeholder="Введите название услуги на казахском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Название услуги на английском" prop="title">
          <el-input v-model="checkup_service.title_en" placeholder="Введите название услуги на английском"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="Клиники" prop="checkup_clinics_id">
          <el-select v-model="checkup_service.checkup_clinics_id" placeholder="Выберите клинику" type="select">
            <el-option
                v-for="item in checkupServiceClinics"
                :key="item.id"
                :label="item.clinic_title"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <el-row slot="header" align="middle" justify="space-between" type="flex">
      <b>Список предоставляемых услуг</b>
      <div class="el-button el-button--primary el-button--small"
           @click="create =! create">
        Добавить
      </div>
    </el-row>
    <el-row>
      <el-tabs
          type="card"
          value="services"
          @tab-click="handleClick"
      >
        <el-tab-pane
            label="Услуги врачей"
            name="services"
        ></el-tab-pane>
        <el-tab-pane
            label="Услуги клиник"
            name="clinic-services"
        ></el-tab-pane>
        <el-tab-pane
            label="Чекапы"
            name="checkups"
        ></el-tab-pane>
        <el-tab-pane
            label="Чекапы (клиники)"
            name="checkup_clinics"
        ></el-tab-pane>
        <el-tab-pane
            label="Чекапы (услуги)"
            name="checkup_services"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-table
        v-loading="loading"
        v-if="actionType === 'services' || actionType === 'clinic-services'"
        :data="datas"
        border
        style="width: 100%"
    >
      <el-table-column
          label="Название услуги"
          prop="title"
      >
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column
          label="Название услуги на казахском"
          prop="title_kz"
      >
        <template slot-scope="scope">{{ scope.row.title_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Название услуги на английском"
          prop="title_en"
      >
        <template slot-scope="scope">{{ scope.row.title_en }}</template>
      </el-table-column>
      <el-table-column
          label="Описание услуги"
          prop="description"
      >
        <template slot-scope="scope">{{ scope.row.description }}</template>
      </el-table-column>
      <el-table-column
          label="Описание услуги на казахском"
          prop="description_kz"
      >
        <template slot-scope="scope">{{ scope.row.description_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Описание услуги на английском"
          prop="description_en"
      >
        <template slot-scope="scope">{{ scope.row.description_en }}</template>
      </el-table-column>
      <el-table-column
          label="Стоимость (тг.)"
          prop="price"
      >
        <template slot-scope="scope">{{ scope.row.price }}</template>
      </el-table-column>
      <el-table-column
          v-if="actionType === 'services'"
          label="Тип услуги"
          prop="request_type"
      >
        <template slot-scope="scope">
         <span v-if="scope.row.request_type==null"> Не выбрано</span>
         <span v-if="scope.row.request_type==1"> Онлайн</span>
         <span v-if="scope.row.request_type==2"> Оффлайн</span>

        </template>
      </el-table-column>
      <el-table-column v-if="actionType === 'services'"
                       label="Доктор"
                       prop="name"
      >
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column v-if="actionType === 'services'"
                       label="Доктор на казахском"
                       prop="name_kz"
      >
        <template slot-scope="scope">{{ scope.row.name_kz }}</template>
      </el-table-column>


      <el-table-column v-if="actionType === 'services'"
                       label="Доктор на английском"
                       prop="name_en"
      >
        <template slot-scope="scope">{{ scope.row.name_en }}</template>
      </el-table-column>

      <el-table-column v-if="actionType === 'clinic-services'"
                       label="Клиника"
                       prop="name"
      >
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column v-if="actionType === 'clinic-services'"
                       label="Клиника на казахском"
                       prop="name_kz"
      >
        <template slot-scope="scope">{{ scope.row.name_kz }}</template>
      </el-table-column>
      <el-table-column v-if="actionType === 'clinic-services'"
                       label="Клиника на английском"
                       prop="name_en"
      >
        <template slot-scope="scope">{{ scope.row.name_en }}</template>
      </el-table-column>
      <el-table-column
          label="Действия"
      >
        <template
            slot-scope="scope"
        >
          <table-action-column
              :document-id="scope.row.id"
              :show-remove="scope.row.is_archived === 0"
              :show-restore="scope.row.is_archived === 1"
              :show-view="false"
              module-code="services"
              @edit="edit(scope)"
              @remove="remove(scope)"
              @restore="restore(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-table
        v-loading="loading"
        v-if="actionType === 'checkups'"
        :data="datas"
        border
        style="width: 100%"
    >
      <el-table-column
          label="Название чекапа"
          prop="title"
      >
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column
          label="Название чекапа на казахском"
          prop="title_en"
      >
        <template slot-scope="scope">{{ scope.row.title_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Название чекапа на английском"
          prop="title_en"
      >
        <template slot-scope="scope">{{ scope.row.title_en }}</template>
      </el-table-column>
      <el-table-column label="Изображение" prop="image">
        <template slot-scope="scope"><img :src="scope.row.image" width="50"/></template>
      </el-table-column>
      <el-table-column
          label="Описание"
          prop="description"
      >
        <template slot-scope="scope">{{ scope.row.description }}</template>
      </el-table-column>
      <el-table-column
          label="Описание на кахахском"
          prop="description_kz"
      >
        <template slot-scope="scope">{{ scope.row.description_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Описание на английском"
          prop="description_en"
      >
        <template slot-scope="scope">{{ scope.row.description_en }}</template>
      </el-table-column>
      <el-table-column
          label="Подключено клиник"
          prop="services_count"
      >
        <template slot-scope="scope">{{ scope.row.services_count }}</template>
      </el-table-column>
      <el-table-column
          label="Действия"
      >
        <template
            slot-scope="scope"
        >
          <table-action-column
              :document-id="scope.row.id"
              :show-remove="scope.row.is_archived === 0"
              :show-restore="scope.row.is_archived === 1"
              module-code="services"
              @edit="edit(scope)"
              @remove="remove(scope)"
              @restore="restore(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-table
        v-loading="loading"
        v-if="actionType === 'checkup_clinics'"
        :data="datas"
        border
        style="width: 100%"
    >
      <el-table-column
          label="Название чекапа"
          prop="checkup_title"
      >
        <template slot-scope="scope">{{ scope.row.checkup_title }}</template>
      </el-table-column>
      <el-table-column
          label="Название чекапа на казахском"
          prop="checkup_title_kz"
      >
        <template slot-scope="scope">{{ scope.row.checkup_title_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Название чекапа на английском"
          prop="checkup_title_en"
      >
        <template slot-scope="scope">{{ scope.row.checkup_title_en }}</template>
      </el-table-column>
      <el-table-column
          label="Название клиники"
          prop="clinic_title"
      >
        <template slot-scope="scope">{{ scope.row.clinic_title }}</template>
      </el-table-column>
      <el-table-column
          label="Название клиники на казахском"
          prop="clinic_title_kz"
      >
        <template slot-scope="scope">{{ scope.row.clinic_title_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Название клиники на английском"
          prop="clinic_title_en"
      >
        <template slot-scope="scope">{{ scope.row.clinic_title_en }}</template>
      </el-table-column>
      <el-table-column
          label="Стоимость (тг.)"
          prop="price"
      >
        <template slot-scope="scope">{{ scope.row.price }}</template>
      </el-table-column>
      <el-table-column
          label="Действия"
      >
        <template
            slot-scope="scope"
        >
          <table-action-column
              :document-id="scope.row.id"
              :show-remove="scope.row.is_archived === 0"
              :show-restore="scope.row.is_archived === 1"
              module-code="services"
              @edit="edit(scope)"
              @remove="remove(scope)"
              @restore="restore(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-table
        v-loading="loading"
        v-if="actionType === 'checkup_services'"
        :data="datas"
        border
        style="width: 100%"
    >
      <el-table-column
          label="Название услуги"
          prop="title"
      >
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column
          label="Название услуги на казахском"
          prop="title_kz"
      >
        <template slot-scope="scope">{{ scope.row.title_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Название услуги на английском"
          prop="title_en"
      >
        <template slot-scope="scope">{{ scope.row.title_en }}</template>
      </el-table-column>
      <el-table-column
          label="Название клиники"
          prop="clinic_title"
      >
        <template slot-scope="scope">{{ scope.row.clinic_title }}</template>
      </el-table-column>
      <el-table-column
          label="Название клиники на казахском"
          prop="clinic_title_en"
      >
        <template slot-scope="scope">{{ scope.row.clinic_title_kz }}</template>
      </el-table-column>
      <el-table-column
          label="Название клиники на английском"
          prop="clinic_title_en"
      >
        <template slot-scope="scope">{{ scope.row.clinic_title_en }}</template>
      </el-table-column>
      <el-table-column
          label="Действия"
      >
        <template
            slot-scope="scope"
        >
          <table-action-column
              :document-id="scope.row.id"
              :show-remove="scope.row.is_archived === 0"
              :show-restore="scope.row.is_archived === 1"
              module-code="services"
              @edit="edit(scope)"
              @remove="remove(scope)"
              @restore="restore(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="page.size"
        :total="page.total"
        :current-page="page.current_page"
        layout="prev, pager, next"
        @current-change="paginate"
    ></el-pagination>
  </el-card>
</template>

<script>
import {logger} from "@/modules/logger";
import TableActionColumn from "@/components/TableActionColumn";
import VPopup from "@/components/PopUp";
import axios from "axios";

export default {
  name: "ServicesList",
  components: {
    VPopup,
    TableActionColumn
  },
  data() {
    return {
      actionType: 'services',
      datas: [],
      cities: [],
      files: [],
      doctors: [],
      clinics: [],
      checkups: [],
      checkupServices: [],
      checkupServiceClinics: [],
      create: false,
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      service: {
        id: null,
        index: null,
        name: null,
        title: null,
        description: null,
        title_en: null,
        description_en: null,
        title_kz: null,
        description_kz: null,
        doctor_id: null,
        request_type: null,
        price: null,
        is_archived: null
      },
      clinic_service: {
        id: null,
        clinic_id: null,
        index: null,
        title: null,
        description: null,
        title_en: null,
        description_en: null,
        title_kz: null,
        description_kz: null,
        price: null,
        is_archived: null
      },
      checkup: {
        id: null,
        title: null,
        title_en: null,
        title_kz: null,
        image: null,
        description: null,
        description_en: null,
        description_kz: null,
        clinics_count: null,
        services_count: null,
        is_archived: null
      },
      checkup_clinic: {
        id: null,
        index: null,
        clinic_info_id: null,
        checkup_id: null,
        price: null,
        is_archived: null
      },
      checkup_service: {
        id: null,
        index: null,
        checkup_clinics_id: null,
        title: null,
        title_en: null,
        title_kz: null,
        is_archived: null
      },
      loading: false,
      rules: {
        title: {required: true, message: "Обязательное поле", trigger: "blur"},
        image: {required: true, message: "Обязательное поле", trigger: "blur"},
        description: {required: true, message: "Обязательное поле", trigger: "blur"},
      },
    };
  },
  mounted() {
  },
  async created() {
    this.getCities()
    this.getDoctors()
    this.getClinics()
    this.getCheckups()
    this.getCheckupServiceClinics()
    try {
      await this.getData();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
      logger.error(e);
    }
  },
  methods: {
    async getCities() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/dictionaries/cities/get`, {});
        this.cities = result.data
      } catch (e) {
        throw e;
      }
    },
    async getDoctors() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/users/doctor/all`, {});
        this.doctors = result.data
      } catch (e) {
        throw e;
      }
    },
    async getCheckups() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/checkups/all`, {});
        this.checkups = result.data
      } catch (e) {
        throw e;
      }
    },
    async getCheckupServiceClinics() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/checkup_services/all`, {});
        this.checkupServiceClinics = result.data
      } catch (e) {
        throw e;
      }
    },
    async getClinics() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/users/clinic/all`, {});
        this.clinics = result.data
      } catch (e) {
        throw e;
      }
    },
    async getServices() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/dictionaries/checkup-services`, {});
        this.checkupServices = result.data
      } catch (e) {
        throw e;
      }
    },
    async onSubmit() {
      try {
        await this.$refs.form.validate();
        let addUrl = `${process.env.VUE_APP_API_URL}/admin/${this.actionType}/store`;
        let updateUrl = `${process.env.VUE_APP_API_URL}/admin/${this.actionType}/update`;

        let data = undefined;

        let url = undefined;

        if (this.actionType === 'services') {
          url = (this.service.id != null && this.service.id) ? updateUrl : addUrl;
          data = {
            id: this.service.id,
            name: this.service.name,
            title: this.service.title,
            description: this.service.description,
            title_en: this.service.title_en,
            description_en: this.service.description_en,
            title_kz: this.service.title_kz,
            description_kz: this.service.description_kz,
            doctor_id: this.service.doctor_id,
            request_type: this.service.request_type,
            price: this.service.price
          }
        } else if (this.actionType === 'clinic-services') {
          url = (this.clinic_service.id != null && this.clinic_service.id) ? updateUrl : addUrl;
          data = {
            id: this.clinic_service.id,
            clinic_id: this.clinic_service.clinic_id,
            title: this.clinic_service.title,
            description: this.clinic_service.description,
            title_en: this.clinic_service.title_en,
            description_en: this.clinic_service.description_en,
            title_kz: this.clinic_service.title_kz,
            description_kz: this.clinic_service.description_kz,
            price: this.clinic_service.price
          }
        } else if (this.actionType === 'checkups') {
          url = (this.checkup.id != null && this.checkup.id) ? updateUrl : addUrl;
          data = new FormData();
          data.append('id', this.checkup.id);
          data.append('title', this.checkup.title);
          data.append('description', this.checkup.description);
          data.append('title_en', this.checkup.title_en);
          data.append('description_en', this.checkup.description_en);
          data.append('title_kz', this.checkup.title_kz);
          data.append('description_kz', this.checkup.description_kz);
          if (this.files[0]) {
            data.append('image', this.files[0]);
          } else {
            data.append('image', this.checkup.image)
          }
        } else if (this.actionType === 'checkup_clinics') {
          url = (this.checkup_clinic.id != null && this.checkup_clinic.id) ? updateUrl : addUrl;
          data = {
            id: this.checkup_clinic.id,
            clinic_info_id: this.checkup_clinic.clinic_info_id,
            checkup_id: this.checkup_clinic.checkup_id,
            price: this.checkup_clinic.price
          }
        } else if (this.actionType === 'checkup_services') {
          url = (this.checkup_service.id != null && this.checkup_service.id) ? updateUrl : addUrl;
          data = {
            id: this.checkup_service.id,
            checkup_clinics_id: this.checkup_service.checkup_clinics_id,
            title: this.checkup_service.title,
            title_en: this.checkup_service.title_en,
            title_kz: this.checkup_service.title_kz
          }
        }

        await this.$http.post(url, data);

        this.getData();
        this.$message.success("Успешно");
        this.files = []
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
      }
      this.create = !this.create;
      this.resetService()
    },
    closePopup() {
      this.create = !this.create;
      this.resetService()
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getData(event);
    },
    resetService() {
      if (this.actionType === 'services') {
        this.service = {
          id: null,
          index: null,
          name: null,
          title: null,
          description: null,
          title_en: null,
          description_en: null,
          title_kz: null,
          description_kz: null,
          doctor_id: null,
          request_type: null,
          price: null,
          is_archived: null
        }
      } else if (this.actionType === 'clinic-services') {
        this.clinic_service = {
          id: null,
          clinic_id: null,
          index: null,
          title: null,
          description: null,
          title_en: null,
          description_en: null,
          title_kz: null,
          description_kz: null,
          price: null,
          is_archived: null
        }
      } else if (this.actionType === 'checkups') {
        this.checkup = {
          id: null,
          index: null,
          title: null,
          title_en: null,
          title_kz: null,
          image: null,
          description: null,
          description_en: null,
          description_kz: null,
          clinics_count: null,
          services_count: null,
          is_archived: null
        }
      } else if (this.actionType === 'checkup_clinics') {
        this.checkup_clinic = {
          id: null,
          index: null,
          clinic_info_id: null,
          checkup_id: null,
          price: null,
          is_archived: null
        }
      } else if (this.actionType === 'checkup_services') {
        this.checkup_service = {
          id: null,
          index: null,
          checkup_clinics_id: null,
          title: null,
          title_en: null,
          title_kz: null,
          is_archived: null
        }
      }
    },
    handleClick(item) {
      this.actionType = item.name;
      this.getData()
    },
    async getData(page) {
      var p = ""
      if (page)
        p = "?page=" + page
      this.loading = true;
      this.datas = []
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/${this.actionType}/get${p}`,
            {
              params: {
                offset: this.page.offset,
                limit: this.page.size
              }
            }
        );
        const data = result.data.data.map(item => {
          return item;
        });

        if (data) {
          this.datas = data;
        }
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;
      } catch (e) {
        throw e;
      }
      this.loading = false;
    },
    view(id) {
      this.$router.push({name: "usersViewRoute", params: {id: id}});
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },
    async remove(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/${this.actionType}/archive`,
            {id}
        );
        this.getData();
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    async restore(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/${this.actionType}/activate`,
            {id}
        );
        this.getData();
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    edit(scope) {
      this.create = true;

      if (this.actionType === 'services') {
        this.service = {
          id: scope.row.id,
          index: scope.$index,
          name: scope.row.name,
          title: scope.row.title,
          description: scope.row.description,
          title_en: scope.row.title_en,
          description_en: scope.row.description_en,
          title_kz: scope.row.title_kz,
          description_kz: scope.row.description_kz,
          doctor_id: scope.row.doctor_id,
          request_type: scope.row.request_type,
          price: scope.row.price,
          is_archived: scope.row.is_archived
        }
      } else if (this.actionType === 'clinic-services') {
        this.clinic_service = {
          id: scope.row.id,
          clinic_id: scope.row.clinic_id,
          index: scope.$index,
          title: scope.row.title,
          description: scope.row.description,
          title_en: scope.row.title_en,
          description_en: scope.row.description_en,
          title_kz: scope.row.title_kz,
          description_kz: scope.row.description_kz,
          price: scope.row.price,
          is_archived: scope.row.is_archived
        }
      } else if (this.actionType === 'checkups') {
        this.checkup = {
          id: scope.row.id,
          index: scope.$index,
          title: scope.row.title,
          title_en: scope.row.title_en,
          title_kz: scope.row.title_kz,
          image: scope.row.image,
          description: scope.row.description,
          description_en: scope.row.description_en,
          description_kz: scope.row.description_kz,
          clinics_count: scope.row.clinics_count,
          services_count: scope.row.services_count,
          is_archived: scope.row.is_archived
        }
      } else if (this.actionType === 'checkup_clinics') {
        this.checkup_clinic = {
          id: scope.row.id,
          index: scope.$index,
          clinic_info_id: scope.row.clinic_info_id,
          checkup_id: scope.row.checkup_id,
          price: scope.row.price,
          is_archived: scope.row.is_archived
        }
      } else if (this.actionType === 'checkup_services') {
        this.checkup_service = {
          id: scope.row.id,
          index: scope.$index,
          checkup_clinics_id: scope.row.checkup_clinics_id,
          title: scope.row.title,
          title_en: scope.row.title_en,
          title_kz: scope.row.title_kz,
          is_archived: scope.row.is_archived
        }
      }
    }
  }
};
</script>

<style></style>
