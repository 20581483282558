<template>
  <el-card class="box-card">
    <v-popup
        v-show="create"
        :title="list.id!=null?'Изменить':'Добавить'"
        @closePopup="closePopup"
    >
      <el-form
          ref="form"
          v-loading="loading"
          :model="list"
          :rules="rules"
          label-position="left"
          label-width="auto"
      >
        <el-form-item
            label="Название"
            prop="name"
        >
          <el-input
              v-model="list.title"
              placeholder="Введите название"
          ></el-input>
        </el-form-item>
        <el-form-item
            v-if="actionType!='schedule-time'"
            label="Название на казахском"
            prop="name"
        >
          <el-input
              v-model="list.title_kz"
              placeholder="Введите название на казахском"
          ></el-input>
        </el-form-item>
        <el-form-item
            v-if="actionType!='schedule-time'"
            label="Название на английском"
            prop="name"
        >
          <el-input
              v-model="list.title_en"
              placeholder="Введите название на английском"
          ></el-input>
        </el-form-item>
        <el-button
            type="primary"
            @click="onSubmit"
        >Сохранить
        </el-button>
        <go-back
            @closePopup="closePopup"
        ></go-back>
      </el-form>
    </v-popup>
    <el-tabs v-model="actionType" type="card" value="service" @tab-click="getLists">
      <el-tab-pane label="Города" name="cities"></el-tab-pane>
      <el-tab-pane label="Профессии" name="professions"></el-tab-pane>
      <el-tab-pane label="Время" name="schedule-time"></el-tab-pane>
    </el-tabs>
    <el-row
        slot="header"
        align="middle"
        justify="space-between"
        type="flex"
    >
      <span v-if="actionType=='cities'">Города</span>
      <span v-if="actionType=='professions'">Профессии</span>
      <span v-if="actionType=='schedule-time'">Время</span>
      <div class="el-button el-button--primary el-button--small"
           @click="create=!create; list.title='';list.id=null">
        <span v-if="actionType=='cities'">Добавить город</span>
        <span v-if="actionType=='professions'">Добавить профессию</span>
        <span v-if="actionType=='schedule-time'">Добавить время</span>
      </div>
    </el-row>
    <el-table v-loading="loading" :data="lists" border style="width: 100%">
      <el-table-column
          label="#"
          prop="id"
      ></el-table-column>
      <el-table-column
          v-if="actionType=='schedule-time'"
          label="Название"
          prop="title_time"
      ></el-table-column>
      <el-table-column
          v-if="actionType!='schedule-time'"
          label="Название"
          prop="title"
      ></el-table-column>
      <el-table-column
          v-if="actionType!='schedule-time'"
          label="Название на казахском"
          prop="title_kz"
      ></el-table-column>
      <el-table-column
          v-if="actionType!='schedule-time'"
          label="Название на английском"
          prop="title_en"
      ></el-table-column>
      <el-table-column
          label="Действия"
      >
        <template slot-scope="scope">
          <table-action-column
              :document-id="scope.row.id"
              module-code="lists"
              @edit="edit(scope)"
              @remove="remove(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="page.size"
        :total="page.total"
        :current-page="page.current_page"
        layout="prev, pager, next"
        @current-change="paginate"
    ></el-pagination>
  </el-card>
</template>

<script>
import {logger} from "@/modules/logger";
import TableActionColumn from "@/components/TableActionColumn";
import VPopup from "@/components/PopUp";

export default {
  name: "ListsList",
  components: {
    VPopup,
    TableActionColumn
  },
  data() {
    return {
      actionType: 'cities',
      list: {
        id: null,
        title: null,
        title_en: null,
        title_kz: null
      },
      loading: false,
      create: false,
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      lists: [],
      searching: false,
      rules: {
        title: {required: true, message: "Обязательное поле", trigger: "blur"}
      }
    };
  },
  async created() {
    this.loading = true;
    try {
      await this.getLists();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
      logger.error(e);
    }
    this.loading = false;
  },
  methods: {
    closePopup() {
      this.create = !this.create;
      this.list = {
        id: null,
        title: null,
        title_en: null
      }
    },
    async onSubmit() {
      try {
        await this.$refs.form.validate();
        let addUrl = `${process.env.VUE_APP_API_URL}/admin/dictionaries/${this.actionType}/store`;
        let updateUrl = `${process.env.VUE_APP_API_URL}/admin/dictionaries/${this.actionType}/update`;
        const url = this.list.id ? updateUrl : addUrl;
        await this.$http.post(url, {
          id: this.list.id,
          title: this.list.title,
          title_en: this.list.title_en,
          title_kz: this.list.title_kz
        });
        if (this.list.index) {
          this.lists[this.list.index].title = this.list.title;
        } else {
          this.lists.push(this.list)
        }
        this.$message.success("Успешно");
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
      }
      this.create = !this.create;
      this.list = {
        id: null,
        title: null,
        title_en: null,
        title_kz: null
      }
      await this.getLists();
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getLists();
    },
    async getLists(item = false) {
      if (item) {
        this.actionType = item.name;
      }

      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/dictionaries/${this.actionType}/get`,
            {
              params: {
                offset: this.page.offset,
                limit: this.page.size
              }
            }
        );
        this.lists = result.data;
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;
        logger.info(this.lists);
      } catch (e) {
        throw e;
      }
    },
    async remove(scope) {
      try {
        const index = scope.$index;
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/dictionaries/${this.actionType}/archive`,
            {
              id: id
            }
        );
        this.lists.splice(index, 1);
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
      await this.getLists();
    },
    edit(scope) {
      this.create = true;
      this.list = {
        index: scope.$index,
        id: scope.row.id,
        title: scope.row.title,
        title_en: scope.row.title_en,
        title_kz: scope.row.title_kz
      }
    },
  }
};
</script>

<style scoped></style>
