<template>
  <el-card class="box-card">
    <v-popup
        v-show="create"
        :title="'Изменить'"
        @closePopup="create=!create"
    >
      <el-form
          ref="form"
          label-position="left"
          label-width="auto"
      >
        <el-form-item
            label="Дата записи"
            prop="name"
        >
          <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="editItem.date"
              type="date"
              placeholder="Pick a day">
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="Время записи"
            prop="name"
        >
          <el-input
              v-model="editItem.time"
              v-mask="'##:##:##'"
              placeholder="Arbitrary time">
          </el-input>
        </el-form-item>
        <el-form-item
            label="Статус оплаты"
            prop="name"
        >
          <el-select v-model="editItem.status" placeholder="Select">
            <el-option
                :key="0"
                label="В ожидании"
                :value="0">
            </el-option>
            <el-option
                :key="1"
                label="Оплачено"
                :value="1">
            </el-option>
            <el-option
                :key="2"
                label="Возвращен"
                :value="2">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
            @click="onSubmit"
            type="primary"
        >Сохранить
        </el-button>
        <go-back
            @closePopup="create=!create"
        ></go-back>
      </el-form>
    </v-popup>
    <el-row slot="header" align="middle" justify="space-between" type="flex">
      <b>Список заказов пользователей</b>
      <div style="display: flex; align-items: center"><span class="p-1">Фильтр:</span>
        <div class="p-1">
          <el-date-picker
              style="margin-left: 1rem"
              v-model="filterDate"
              type="daterange"
              range-separator="По"
              @change="filterByDate"
              @keyup.enter.native="filterByDate"
          >
          </el-date-picker>
        </div>
        <div class="p-1">
          <el-select
              v-if="actionType === 'service'"
              v-model="params.doctor_id"
              placeholder="Выберите врача"
              type="select"
              @change="filterByDoctor"
              @keyup.enter.native="filterByDoctor"
          >
            <el-option
                v-for="item in doctors"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </div>
        <div class="p-1">
          <el-select
              v-if="actionType === 'checkup' || actionType === 'clinic'"
              v-model="params.clinic_id"
              placeholder="Выберите клинику"
              type="select"
              @change="filterByClinic"
              @keyup.enter.native="filterByClinic"
          >
            <el-option
                v-for="item in clinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </div>
        <div class="p-1">
          <el-button type="info" @click="reset">Сбросить фильтр</el-button>
        </div>
        <div class="p-1">
          <el-button type="success" @click="exportExcel">Экспорт</el-button>
        </div>
      </div>
    </el-row>
    <el-tabs type="card" value="service" @tab-click="handleClick">
      <el-tab-pane label="Консультации" name="service"></el-tab-pane>
      <el-tab-pane label="Клиники" name="clinic"></el-tab-pane>
      <el-tab-pane label="Услуги" name="checkup"></el-tab-pane>
    </el-tabs>
    <el-table v-loading="loading" ref="elTable" :data="dataList" border style="width: 100%">
      <el-table-column
          label="Пользователь"
          prop="user_name"
      ></el-table-column>
      <el-table-column
          label="Номер телефона"
          prop="user_phone"
      >
        <template slot-scope="scope">
          +{{ scope.row.user_phone }}
        </template>


      </el-table-column>
      <el-table-column
          label="Услуги"
          prop="title"
      >
        <template slot-scope="scope">
                <span>{{ scope.row.title }}
                  <span style="color:red;">
                  <template v-if="scope.row.record_type===1">Онлайн</template>
                  <template v-else-if="scope.row.record_type===2">Оффлайн</template>
                 </span>
                </span>
        </template>
      </el-table-column>
      <el-table-column
          v-if="actionType === 'checkup' || actionType === 'clinic'"
          label="Клиника"
          prop="clinic_name"
      ></el-table-column>
      <el-table-column
          v-if=" actionType === 'clinic' || actionType === 'checkup'"
          label="Email клиники"
          prop="clinic_email"
      ></el-table-column>
      <el-table-column
          v-if="actionType === 'service'"
          label="Доктор"
          prop="doctor_name"
      ></el-table-column>
      <el-table-column
          v-if="actionType === 'service'"
          label="Email врача"
          prop="doctor_email"
      ></el-table-column>
      <el-table-column
          label="Стоимость (тг.)"
          prop="price"
      >
        <template slot-scope="scope">
          {{ scope.row.price }} тенге
        </template>
      </el-table-column>
      <el-table-column
          label="Дата и время услуги"
      >
        <template slot-scope="scope">
          {{ scope.row.date }} в {{ scope.row.time }}
        </template>
      </el-table-column>
      <el-table-column
          label="Заключение врача"
          prop="conclusion"
      ></el-table-column>
      <el-table-column
          label="Отзыв пациента"
          v-if="actionType === 'service' || actionType === 'clinic'"
          prop="feedback_from_user"
      ></el-table-column>

      <el-table-column
          label="Дата и время оплаты"
      >
        <template slot-scope="scope">
          {{ scope.row.payment_time }}
        </template>
      </el-table-column>

      <el-table-column
          label="Статус оплаты"
      >
        <template slot-scope="scope">
          <el-button
              style="width: 100%"
              v-if="scope.row.status == 0"
              type="warning"
          >В ожидании
          </el-button>
          <el-button
              style="width: 100%"
              v-if="scope.row.status == 1"
              type="success"
          >Оплачен
          </el-button>
          <el-button
              style="width: 100%"
              v-if="scope.row.status == 2"
              type="grey"
          >Возвращен
          </el-button>
          <el-button
              style="width: 100%"
              v-if="scope.row.status== -1"
              type="danger"
          >Ошибка
          </el-button>
        </template>
      </el-table-column>


      <el-table-column
          label="Статус записи"
      >
        <template slot-scope="scope">
          <el-button
              style="width: 100%"
              v-if="scope.row.is_approved == 0"
              type="warning"
          >Новое
          </el-button>
          <el-button
              style="width: 100%"
              v-if="scope.row.is_approved == 1"
              type="success"
          >Подтверждено
          </el-button>
          <el-button
              style="width: 100%"
              v-if="scope.row.is_approved == 2"
              type="grey"
          >Прошедшее
          </el-button>
          <el-button
              style="width: 100%; text-align: center;    display: flex;
    justify-content: center;"
              v-if="scope.row.is_approved == 3"
              type="danger"
          >Отменено
            <br v-if=" scope.row.cancel_user">
            {{
              scope.row.cancel_user == 1 ? 'Пользователем' : ""
            }}
            {{
              scope.row.cancel_user == 2 ? 'Доктором' : ""
            }}
            {{
              scope.row.cancel_user == 3 ? 'Администратором' : ""
            }}
            {{
              scope.row.cancel_user == 4 ? 'Клиникой' : ""
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="Действия"
      >
        <template slot-scope="scope">
          <el-button
              icon="el-icon-close"
              style="color:red;margin: 0.5rem"
              @click="revertPayment(scope)"
          ></el-button>
          <el-button
              icon="el-icon-edit"
              style="color:green;margin: 0.5rem"
              @click="editPayment(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="page.size"
        :total="page.total"
        :current-page="page.current_page"
        layout="prev, pager, next"
        @current-change="paginate"
    ></el-pagination>
  </el-card>
</template>

<script>
import {logger} from "@/modules/logger";
import moment from "moment";
import axios from "axios";
import VPopup from "@/components/PopUp";

export default {
  name: "ServiceOrdersList",
  components: {VPopup},
  data() {
    return {
      filterDate: '',
      create: false,
      editItem: {
        id: 0,
        time: '',
        date: '',
        status: ''
      },
      loading: false,
      actionType: 'service',
      doctors: [],
      clinics: [],
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      query: {
        date: undefined,
        doctor_id: null,
        clinic_id: null
      },
      params: {
        date: undefined,
        doctor_id: null,
        clinic_id: null
      },
      dataList: []
    };
  },
  async created() {
    this.loading = true;
    try {
      this.getDoctors()
      this.getClinics()
      await this.getData();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
    }
    this.loading = false;
  },
  methods: {

    async exportExcel() {


      var link = document.createElement('a');
      link.setAttribute('href',`${process.env.VUE_APP_API_URL}/web/${this.actionType}/export`);
      link.setAttribute('download','download');
      onload=link.click();


    },
    filterByDate() {
      this.query.date = this.params.date ? moment(this.params.date, "YYYY-MM-DD").format("YYYY-MM-DD") : null
      this.getData()
    },
    filterByDoctor() {
      this.query.doctor_id = this.params.doctor_id ? this.params.doctor_id : null
      this.getData()
    },
    filterByClinic() {
      this.query.clinic_id = this.params.clinic_id ? this.params.clinic_id : null
      this.getData()
    },
    reset() {
      this.query.date = null
      this.query.doctor_id = null
      this.query.clinic_id = null
      this.params.date = null
      this.params.doctor_id = null
      this.params.clinic_id = null
      this.getData()
    },
    async getDoctors() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/users/doctor/all`, {});
        this.doctors = result.data
      } catch (e) {
        throw e;
      }
    },
    async getClinics() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/admin/users/clinic/all`, {});
        this.clinics = result.data
      } catch (e) {
        throw e;
      }
    },
    async revertPayment(scope) {
      this.$confirm(`Вы действительно хотите отменить запись? Действие нельзя отменить. ВНИМАНИЕ! Средства за заказ необходимо будет вернуть самостоятельно`, "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning"
      })
          .then(() => {
            this.$http.post(
                `${process.env.VUE_APP_API_URL}/admin/service_orders/${this.actionType}/cancel`,
                {
                  id: scope.row.id,
                }
            );
            this.getData()
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Действие отменено"
            });
          });
    },
    editPayment(scope) {
      console.log(scope)
      console.log(this.actionType)
      this.editItem.id = scope.row.id
      this.editItem.time = scope.row.time
      this.editItem.date = scope.row.date
      this.editItem.status = scope.row.status
      this.create = true
    },
    handleClick(item) {
      this.actionType = item.name;
      this.getData()
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getData();
    },

    async getData() {
      this.loading = true
      this.dataList = []
      // eslint-disable-next-line no-useless-catch
      try {


        if (this.filterDate[0]) {
          var dateStart = new Date(this.filterDate[0])
          dateStart.setDate(dateStart.getDate() + 1)
        }
        if (this.filterDate[1]) {
          var dateEnd = new Date(this.filterDate[1])
          dateEnd.setDate(dateEnd.getDate() + 1)
        }


        const result = await this.$http.get(
            `${process.env.VUE_APP_API_URL}/admin/service_orders/${this.actionType}/get`,
            {
              params: {
                page: this.page.offset,
                dateStart: dateStart,
                dateEnd: dateEnd,
                doctor_id: this.query.doctor_id,
                clinic_id: this.query.clinic_id
              }
            }
        );
        this.dataList = result.data.data;

        // for (let i = 0; i < this.dataList.length; i++) {
        //   if (this.dataList[i].created_at) {
        //
        //     this.dataList[i].created_at = this.dataList[i].created_at.substr(0, 10) + ' в ' + this.dataList[i].created_at.substr(11, 8)
        //   }
        //
        // }
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;
      } catch (e) {
        throw e;
      }
      this.loading = false
    },

    async remove(scope) {
      try {
        const index = scope.$index;
        const id = scope.row.id;
        await this.$http.delete(
            `${process.env.VUE_APP_API_URL}/v1/departments/delete`,
            {
              params: {
                id: id
              }
            }
        );
        this.dataList.splice(index, 1);
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    edit(id) {
      this.$router.push({name: "departmentEditRoute", params: {id: id}});
    },
    async onSubmit() {
      await this.$http.post(
          `${process.env.VUE_APP_API_URL}/admin/service_orders/${this.actionType}/edit`,
          {
            id: this.editItem.id,
            time: this.editItem.time,
            date: this.editItem.date,
            status: this.editItem.status
          }
      );
      this.create = false
      this.getData()
    }

  }
};
</script>

<style scoped></style>
